/* eslint-disable no-param-reassign */
import axios from "axios";
const apiURL = 'https://api.fzyp001.vip';


const requests = axios.create({
    method: "post",
    baseURL: apiURL,
    timeout: 10000000 * 1000,
    headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded",
    },

});

axios.defaults.responseType = 'json' // 默认数据响应类型
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true // 表示跨域请求时是否需要使用凭证

requests.interceptors.request.use(
    (config) => {

        config.headers["lang"] = "zh-CN";


        return config;
    },
    (error) => Promise.reject(error)
);
// 301 302
requests.interceptors.response.use(
    (response) => {
        if (response.data.code == 401 || response.data.code == 422) {
            // 弹出授权
        }
        // if (response.data.code != 0) {
        //     if (response.data.code == 401 || response.data.code == 422) {
        //         router.replace("/login")

        //     }else{
        //         Toast(response.data.msg);
        //     }


        // }
        // if()
        // 
        return response;
    },
    (error) => Promise.reject(error)
);

export {
    requests
};