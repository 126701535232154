import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Activity from "../views/Activity.vue";
import Help from "../views/Help.vue";
import About from "../views/About.vue";
import Protocol from "../views/Protocol.vue";
import Download from "../views/Download.vue";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
    {
        path: "/", //首页
        name: "Home",
        component: Home,
    },
  
    {
        path: "/activity", //首页
        name: "Activity",
        component: Activity,
    },
    {
        path: "/help", //首页
        name: "Help",
        component: Help,
    },
    {
        path: "/about", //首页
        name: "About",
        component: About,
    },
    {
        path: "/protocol", //首页
        name: "Protocol",
        component: Protocol,
    },
    {
        path: "/download", //首页
        name: "Download",
        component: Download,
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

export default router;