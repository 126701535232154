<template>
  <div class="Download">
    <div class="header_down_txt">
      <div class="txt_one">随时随地开启交易</div>
      <div class="txt_two">下载客户端，所有交易尽在手中。</div>
    </div>
    <div class="download_container">
      <div class="down_left_box">
        <img class="down_01" src="../assets/down_01.png" alt="" />
        <div class="down_icons">
          <img
            class="down_icon"
            @click="Donw(1)"
            src="../assets/ios.png"
            alt=""
          />
          <img
            class="down_icon"
            @click="Donw(2)"
            src="../assets/chilun.png"
            alt=""
          />

          <img
            class="down_icon"
            @click="Donw(3)"
            src="../assets/anzhuo.png"
            alt=""
          />
          <!-- <van-popover v-model="showPopover" trigger="click" placement="top">
                        <div class="download_pop_box"
                            style="display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 5px;">
                            <div class="pop_title" style="font-size: 18px; padding-top: 15px; color: black;">扫码下载APP
                            </div>
                            <img style="width:120px;" class="main_013" src="../assets/main_013.png" alt="">
                        </div>
                        <template #reference>
                            <img class="down_icon" src="../assets/down_03.png" alt="">
                        </template>
                    </van-popover>
                    <van-popover v-model="showLL" trigger="click" placement="top">
                        <div class="download_pop_box"
                            style="display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 5px;">
                            <div class="pop_title" style="font-size: 18px; padding-top: 15px; color: black;">扫码下载</div>
                            <img style="width:120px;" class="main_013" src="../assets/main_013.png" alt="">
                        </div>
                        <template #reference>
                            <img class="down_icon" src="../assets/down_04.png" alt="">
                        </template>
                    </van-popover> -->
        </div>
      </div>
      <div class="down_right_box">
        <div class="right_icon_box">
          <img class="down_02"  src="../assets/down_02.png" alt="" />
        </div>
        <img class="down_icon"  @click="Donw(4)" src="../assets/down_05.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import brands_01 from "../assets/banner_01.png";
export default {
  name: "",
  data() {
    return {
      showPopover: false,
      showLL: false,
      HomeData: {},
      Links:{},
    };
  },
  methods: {
    Donw(num) {
      var link = "";
      if (num == 1) {
        // ios
        link = this.Links.iosDownloadLink;
        window.open(link);
      } else if (num == 2) {
        // ios mobile
        link = this.Links.iphoneH5DecDoc;
        window.location.assign(link);
      } else if (num == 3) {
        // 安卓
        link = this.Links.androidDownloadLink;
        window.open(link);
      } else {
        // pc
        link = this.Links.winDownloadLink;
        window.location.assign(link);
      }
    },
    getSiteHomePageSetting() {
      this.$api.getSiteHomePageSetting().then((res) => {
        if (res.data.status == 0) {
          try {
            this.HomeData = res.data.data.homePage;
            this.Links = res.data.data.downloadLink;
          } catch {}
        }
      });
    },
  },
  mounted() {
    //dom 准备完毕
  },
  created() {
    //应用创建
    this.getSiteHomePageSetting();
  },
};
</script>

<style lang="less" scoped>
.Download {
  width: 100%;
  background: #f8f7f7;
  padding: 60px 0;
  .header_down_txt {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .txt_one {
      font-size: 40px;
      color: #231815;
      margin-bottom: 10px;
    }
    .txt_two {
      font-size: 18px;
      color: #231815;
    }
  }
  .download_container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 52px;

    .down_left_box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .down_01 {
        width: 339px;
        height: 336px;
      }

      .down_icons {
        width: 340px;

        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .down_icon {
        width: 130px;
        margin-right: 12px;
        height: 42px;
      }
    }

    .down_right_box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .right_icon_box {
        height: 336px;
        position: relative;

        .down_02 {
          width: 484px;
          height: 286px;
        }
      }

      .down_icon {
        width: 160px;
        height: 42px;
      }
    }
  }
}
</style>