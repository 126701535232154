<template>
    <div class="activity">
        <div class="activity_header_box">
            用户福利中心
        </div>
        <div class="activity_main_container">
            <div class="activity_box">
                <!-- 切换的tabs -->
                <div class="ac_tabs_box">
                    <div class="tab_list">
                        <div @click="changeIndex(1)" :class="{ 'tab_item': true, 'tab_item_select': index === 1 }">所有活动
                        </div>
                        <div @click="changeIndex(2)" :class="{ 'tab_item': true, 'tab_item_select': index === 2 }">进行中
                        </div>
                        <div @click="changeIndex(3)" :class="{ 'tab_item': true, 'tab_item_select': index === 3 }">已结束
                        </div>
                    </div>
                </div>
                <van-empty v-if="!items || items.length <= 0" :image="empty" description="暂无活动" />
                <van-empty  v-if="index == 2" :image="empty" description="暂无活动" />
                <van-empty  v-if="index == 3" :image="empty" description="暂无活动" />


                <div class="activity_list" v-if="items.length > 0 && index==1" >
                    <div class="activity_item" v-for="(item, index) in items" :key="index" @click="showNotice = true">
                        <img class="activity_02" src="../assets/activity_02.png" alt="">
                        <div class="ac_title">注册送礼 人人有份</div>
                        <div class="ac_time">活动时间:2023-08-01 - 2024-09-01</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 公告 -->

        <van-popup class="notice_container" v-model:show="showNotice"
            :style="{ width: '650px', height: '500px', background: 'white' }" style="border-radius: 8px" closeable>
            <div class="activity_details">
                <div class="ac_header_box">
                    注册送礼 人人有份
                </div>
                <div class="content">
                    <img src="@/assets/img1.png" style="width: 100%;"/>
                </div>
                <div class="foot_box">
                    <div class="fun_btn" @click="showNotice=false">确认</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import empty from "../assets/empty.png";
export default {
    name: "",

    data() {
        return {
            index: 1,
            items: [{}],
            empty: empty,
            showNotice: false
        };
    },
    methods: {
        changeIndex(num) {
            this.index = num;
        }
    },
    mounted() {
        //dom 准备完毕
    },
    created() {
        //应用创建

    },
};
</script>
<style>
.van-empty__image {
    height: 220px !important;
    width: 318px !important;
}

.van-empty {
    margin-top: 50px !important;
}

.van-popup__close-icon--top-right {
    color: black !important;
}
</style>
<style lang="less" scoped>
.activity {
    width: 100%;
    padding-bottom: 70px;
    background: rgb(246, 246, 248);

    .activity_details {
        display: flex;
        flex-direction: column;
        height: 500px;

        .ac_header_box {
            background: #f7b329 !important;
            height: 50px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: left;
            font-size: 18px;
            color: black;
        }

        .content {
            flex: 1;
            height: 100%;
            overflow: auto;
            overflow-y: auto;
        }

        .foot_box {
            height: 50px;
            border-top: 1px solid black;
            display: flex;
            align-items: center;
            justify-content: center;

            .fun_btn {
                border-color: #f7b329 !important;
                background-color: #f7b329 !important;
                color: #000 !important;
                padding: 0 66px !important;
                border-radius: 20px !important;
                height: 40px !important;
                line-height: 40px !important;
                font-weight: 500 !important;
                color: #333;
                font-size: 18px;
            }
        }
    }

    .activity_main_container {
        width: 100%;

        .activity_box {
            width: 1200px;
            margin: 30px auto;

            .ac_tabs_box {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .tab_list {
                    display: flex;

                    .tab_item {
                        height: 56px;
                        width: 110px;
                        color: #231815;
                        font-size: 19px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .tab_item_select {
                        height: 56px;
                        width: 110px;
                        background: #f7b329;
                        color: #231815;
                        border-radius: 5px;
                        font-size: 19px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

            }

            .activity_list {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .activity_item {
                    width: 370px;
                    border-radius: 8px;
                    margin-top: 30px;

                    background: white;

                    .activity_02 {
                        width: 100%;
                        height: 220px;

                    }

                    .ac_title {
                        color: rgb(51, 51, 51);
                        font-size: 16px;
                        padding: 20px;
                    }

                    .ac_time {
                        color: rgb(154, 154, 154);
                        font-size: 12px;
                        padding: 0 20px 20px 20px;
                    }
                }
            }

        }

    }

    .activity_header_box {
        background-image: url('../assets/activity_01.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 255px;
        color: #fff;
        font-size: 40px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;

    }


}
</style>