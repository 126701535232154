<template>
  <div class="help">
    <div class="help_header_box">帮助中心</div>
    <!-- 主要内容 -->
    <div class="navigation_container">
      <!-- 导航 -->
      <div class="navigation_box">帮助中心 > 新手教程 > 如何注册</div>
    </div>
    <!-- 主要内容 -->
    <div class="help_content_box">
      <!-- 左侧导航 -->
      <div class="left_nav_box">
        <div class="nav_title">新手教程</div>
        <van-steps
          direction="vertical"
          :active="active"
          @click-step="handleStepClick"
        >
          <van-step>如何交易</van-step>
          <van-step>如何提现</van-step>
          <van-step>如何充值</van-step>
          <van-step>如何申请合约</van-step>
          <van-step>如何注册</van-step>
        </van-steps>
        <div class="nav_title">帮助中心</div>
        <van-steps
          direction="vertical"
          :active="activeHelp"
          @click-step="changeActiveHelp"
        >
          <van-step>常见问题</van-step>
        </van-steps>
      </div>
      <!-- 右侧内容 -->
      <div class="right_content_box" v-if="active == 0">
        <div class="content_txt_title">如何交易</div>
        <div class="line"></div>
        <div class="content_txt_info">
          1.成功申请合约后，点击“行情“<br /><br />
          2.点击右上角”放大镜“输入股票名称或者代码搜索。<br /><br />
          3.选择股票点击“交易”，进入交易界面！<br /><br />
          4.选择合约即可进行买卖交易。
        </div>
      </div>

      <div class="right_content_box" v-if="active == 1">
        <div class="content_txt_title">如何提现</div>
        <div class="line"></div>
        <div class="content_txt_info">
          1.当您的合约持仓股票整体盈利大于100元时。<br />

          2.您需要卖出盈利持仓，卖出后的资金到达您的合约余额中。<br />

          3.返回“合约”中心，找到刚刚卖出盈利持仓的合约。<br />

          4.点击“提盈”，提盈成功后，盈利资金到达您的账户余额！<br />

          5.账户余额大于100元时，您点击“我的”，点击上方“提现”功能。<br />

          6.进入提现界面后，选择您开户时绑定的银行卡，输入提现金额，输入提现密码，并点击确认！<br />

          7.提现成功后，等待工作人员处理，即可立即到账！<br />

          温馨提示：每日提现次数为5次，达到次数请于次日再申请提现。
        </div>
      </div>
      <div class="right_content_box" v-if="active == 2">
        <div class="content_txt_title">如何充值</div>
        <div class="line"></div>
        <div class="content_txt_info">
          1.注册成功后，第一时间进行开户认证，绑定您的身份证、银行卡信息！<br />
          2.进入首页点击“充值返现”。<br />
          3.输入金额金额，以下方官方最新收款账户信息为准，复制收款人、卡号信息、银行类型到您的个人银行app中进行转账汇款，转账金额必须与填写金额一致！<br />
          4.在个人银行app中转账成功后，返回{{ HomeData.siteName || "" }}app充值界面点击“提交”，提交充值申请！<br />
          5.等待财务部门审核，即可到账！
        </div>
      </div>
      <div class="right_content_box" v-if="active == 3">
        <div class="content_txt_title">如何申请合约</div>
        <div class="line"></div>
        <div class="content_txt_info">
          1.充值成功后，即可点击下方“合约”功能，在点击立即申请合约。<br />

          2.选择您的合约类型：按天、按周、按月、免息。<br />

          3.输入您需要投入的保证金，选择您的杠杆倍率。<br />

          4.确定合约的总配资金额、警戒线、平仓线信息。<br />

          5.合约申请成功后，可点击“合约”，查看您的合约信息！
        </div>
      </div>
      <div class="right_content_box" v-if="active == 4">
        <div class="content_txt_title">如何注册</div>
        <div class="line"></div>
        <div class="content_txt_info">
          第一步：点击“我的”。<br />

          第二步：点击 "注册/登录"分别填写
          "手机号"-"验证码"-"手机验证码"-"密码"-"推荐人推广码"(可填可不填)。<br />

          第三步：点击 "用户登录" 分别输入
          "手机号"-"密码"-"验证码"—点击进入。<br />

          第四步: 点击 "用户中心"-选择 "充值"，"充值金额"，填写
          "相关信息"-转账成功后点击 "我已成功付款"提交。<br />

          第五步：选择您的配资玩法-直接点击 "按天配资" "按月配资" "按周配资"
          "免息配资"。<br />

          第六步：提交申请之后,我们可以点击 "我的自选"-点击 "添加自选股"
          输入股票代码添加。<br />

          第七步：点击您要操作的股票-点击“交易”-点击“ 买入”。<br />

          第八步: 实盘交易 "确认"后,可以到 交易页面-"委托"里面进行查询。<br />

          第九步：点击
          "合约"-点击"结算"进行结算合约，清仓结算后可以直接提现或者是继续其他配资。<br />

          第十步：提现：提现前您需要先到 "用户中心"-"实名认证"-认证完即可提现
        </div>
      </div>
      <div class="right_content_box" v-if="activeHelp == 0">
        <div class="content_txt_title">常见问题</div>
        <div class="line"></div>
        <div class="content_txt_info">
          什么是金融杠杆原理？<br />

          答：在金融衍生品中，资金杠杆原理与这个规则本质上类似，都是以一定比率的保证金撬动大资金，是金融业中最常见的投资手段，例如股票配资、外汇、期货交易、黄金等贵金属交易。比如，常见的股票配资的杠杆比例为1:10，相当于10万的股票，你只用给1万就可以交易，假设股票上涨10%，也就是10万的10%即上涨到11万，你就赚了1万
          ，相当于本金上涨了100%。<br /><br />
          为什么杠杆原理的投资要注意止损？<br />
          答：杠杆原理既然能放大收益，也能放大亏损，风险与收益总是相对的，没有投资是没有风险的？关键就在于你是否懂得控制。<br /><br />
          股票配资发生纠纷如何处理？<br />
          答：配资业务实质上属于借贷关系，配资者和配资公司必须共同遵守合同，有纠纷必须以合同为准，协商处理，若协商不了，双方可以选择起诉。配资者在配资前一定要把合同全部了解清楚，签署的文件资料需要全部做保留。共同遵守合同条款，比如平仓行为，配资公司设置平仓线主要是为了防范风险，万一配资者的账户到了平仓线，配资系统是有权将账户进行股票平仓的，最后账户盈亏由配资者自负。
          如果投资者个人在平仓行为发生后认为不合理，这个就需要配资双方协商；如果是配资公司违约，投资者在协商不行的情况下可以选择起诉。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import brands_01 from "../assets/banner_01.png";
export default {
  name: "",
  data() {
    return {
      active: 1,
      activeHelp: -1,
      number: this.$route.query.number || 0,
      HomeData:{},
    };
  },
  watch: {
    number(num1, num2) {
    },
  },
  methods: {
    handleStepClick(index) {
      console.log("changeActive", index);
      this.active = index;
      this.activeHelp = -1;
    },
    changeActiveHelp() {
      this.active = -1;
      this.activeHelp = 0;
    },
  },
  mounted() {
    //dom 准备完毕
  },
  created() {
    //应用创建
    this.$bus.$on("help", (index) => {
      if (index == 5) {
        this.active = -1;
        this.activeHelp = 0;
      } else {
        this.active = index;
        this.activeHelp = -1;
      }
    });

     // 获取
     try {
      var data =  localStorage.getItem("data");
      if(data){
        this.HomeData = JSON.parse(data);
      }
    }catch{

    }
  },
};
</script>
<style>
.van-hairline {
  cursor: pointer !important;
}
</style>
<style lang="less" scoped>
.help {
  width: 100%;
  background: #fff;

  .help_content_box {
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 100px;
    display: flex;

    .left_nav_box {
      width: 180px;

      .nav_title {
        color: #231815;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .right_content_box {
      flex: 1;

      .content_txt_title {
        color: #231815;
        font-size: 20px;
        padding: 20px 0;
        border-bottom: 1px solid rgb(229, 229, 229);
      }

      .content_txt_info {
        padding-top: 20px;
        font-size: 14px;
        color: #515a6e;
      }
    }
  }

  .navigation_container {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    height: 60px;
    width: 100%;

    .navigation_box {
      height: 100%;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: left;
      font-size: 14px;
      color: #515a6e;
    }
  }

  .help_header_box {
    background-image: url("../assets/activity_01.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 255px;
    color: #fff;
    font-size: 40px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>