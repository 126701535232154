<template>
  <div class="home">
    <div class="swip_box">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in bannerList" :key="item">
          <img style="width: 100%; height: 460px" :src="item" alt="" />
        </van-swipe-item>
        <!-- <van-swipe-item>
          <img src="../assets/banner_02.png" alt=""
        /></van-swipe-item>
        <van-swipe-item>
          <img src="../assets/banner_03.png" alt=""
        /></van-swipe-item> -->
      </van-swipe>

      <div class="isRegister_box">
        <div class="is_txt_one">创新型互联网平台</div>
        <div class="is_txt_two">行业领导者</div>
        <div class="is_txt_three">资金由券商银行监管，100%实盘交易</div>
        <div class="re_now">
          <div class="re_btn" @click="showRegister = true">立即注册</div>
        </div>
      </div>
    </div>
    <div class="notice_container">
      <div class="notice_box">
        <van-notice-bar
          background="#f8f8f8"
          color="#333"
          :right-icon="main_01"
          :left-icon="main_01"
          scrollable
          :text="
            '关于劳动节休市安排的通知 尊敬的会员：根据中国证监会有关通知精神，现将2024年劳动节休市安排通知如下：劳动节：5月1日（星期三）至5月5日（星期日）休市，5月6日（星期一）起照常开市。4月28日（星期日）、5月11日（星期六）为周末休市。' +
            HomeData.siteName +
            ' -  2024年4月24日'
          "
        />
        <!-- <img class="main_02" src="../assets/main_02.png" alt=""> -->
      </div>
    </div>

    <div class="home_main_box">
      <div class="pcinfo_box">
        <!-- 1 -->
        <div class="info_item_box">
          <img class="info_icon" src="../assets/main_03.png" alt="" />
          <div class="info_title">LV2高级行情</div>
          <div class="content">
            实力惠民，沪深Lv2行情数据免费用，买卖10档行情，进一步完善了{{
              HomeData.siteName || ""
            }}行情功能的丰富度和专业性，满足了投资者们的个性化行情数据需求。
          </div>
        </div>
        <!-- 2 -->
        <div class="info_item_box">
          <img class="info_icon" src="../assets/main_04.png" alt="" />
          <div class="info_title">贴心客服</div>
          <div class="content">
            一对一专属的贴心客服服务让您享受到更便捷、更专业、更优质的服务。客户信息加密处理,专业24小时处理，您的账户以及权益将得到充分的保障。
          </div>
        </div>
        <!-- 2 -->
        <div class="info_item_box">
          <img class="info_icon" src="../assets/main_05.png" alt="" />
          <div class="info_title">杠杆交易</div>
          <div class="content">
            无任何门槛，杠杆倍数行业最高，优化用户交易流程，扩大数倍利润，只需投入一小部分保证金就能获得与常规交易相同的利润，合约杠杆可以带来如数倍的回报。
          </div>
        </div>
        <!-- 2 -->
        <div class="info_item_box">
          <img class="info_icon" src="../assets/main_06.png" alt="" />
          <div class="info_title">闪电充提</div>
          <div class="content">
            支持多种支付方式，充值1分钟到账，提现3分钟到账，资金隔离存管，按照相关监管法规进行合规管理，让客户放心炒股，放心金融，放心投资。
          </div>
        </div>
        <!-- 2 -->
        <div class="info_item_box">
          <img class="info_icon" src="../assets/main_07.png" alt="" />
          <div class="info_title">质量交易</div>
          <div class="content">
            真正的实盘交易，杜绝一切虚拟盘，所有数据公开透明，可实时查看交易信息数据。穿仓免责，为用户承担额外风险，让客户放心操盘交易。
          </div>
        </div>
        <!-- 2 -->
        <div class="info_item_box">
          <img class="info_icon" src="../assets/main_08.png" alt="" />
          <div class="info_title">资金安全</div>
          <div class="content">
            超低利息低至0.6分，开户只需要3分钟。资金通过银行 SSL
            双重监管，保护您的财产安全，安全交易，致力于打造互联网第一金融权威平台。
          </div>
        </div>
      </div>

      <!-- 数量 -->
      <div class="numinfo_box">
        <div class="numinfo_item">
          <div class="nun_txt">¥ {{ HomeData.benchmarkAmount || 0 }}</div>
          <div class="num_title">配资金额</div>
        </div>
        <div class="num_line"></div>
        <div class="numinfo_item">
          <div class="nun_txt">{{ HomeData.benchmarkPeople || 0 }}</div>
          <div class="num_title">配资人数</div>
        </div>
        <div class="num_line"></div>
        <div class="numinfo_item">
          <div class="nun_txt">7X 24</div>
          <div class="num_title">服务支持</div>
        </div>
        <div class="num_line"></div>
      </div>
    </div>
    <div class="home_main_box_two">
      <div class="trade_box">
        <div class="trade_title">随时随地开启交易</div>
        <div class="trade_tip">与多种装置相容，可以既安全又便利地开始交易</div>
        <div class="trade_download_box">
          <div class="left_icon">
            <img class="main_09" src="../assets/main_09.png" alt="" />
          </div>
          <div class="download_images_box">
            <div class="images_box">
              <img
                class="down_icon"
                @click="Donw(1)"
                src="../assets/ios.png"
                alt=""
              />
              <img
                class="down_icon"
                @click="Donw(2)"
                src="../assets/chilun.png"
                alt=""
              />

              <img
                class="down_icon"
                @click="Donw(3)"
                src="../assets/anzhuo.png"
                alt=""
              />
              <img
                class="down_icon"
                @click="Donw(4)"
                src="../assets/main_011.png"
                alt=""
              />
              <!-- <img class="down_icon" src="../assets/main_010.png" alt="" /> -->

              <!-- <van-popover
                v-model="showPopover"
                trigger="click"
                placement="top"
              >
                <div
                  class="download_pop_box"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    padding: 5px;
                  "
                >
                  <div
                    class="pop_title"
                    style="font-size: 18px; padding-top: 15px; color: black"
                  >
                    扫码下载APP
                  </div>
                  <img
                    style="width: 120px"
                    class="main_013"
                    src="../assets/main_013.png"
                    alt=""
                  />
                </div>
                <template #reference>
                </template>
              </van-popover> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home_main_box_three">
      <div class="support_box">
        <img class="main_014" src="../assets/main_014.png" alt="" />
      </div>
    </div>

    <van-popup
      class="notice_container"
      v-model:show="showRegister"
      :style="{ width: '334px', background: 'white' }"
      style="border-radius: 8px"
      closeable
    >
      <div class="home_details">
        <div class="ac_header_box">手机快速注册</div>
        <div class="content">
          <div class="input_box">
            <div class="area_txt">
              <img class="register_01" src="../assets/register_01.png" alt="" />
            </div>
            <!-- 输入框部分 -->
            <input
              v-model="phone"
              type="number"
              class="input_main"
              placeholder="请输入手机号"
            />
          </div>
          <div class="input_box">
            <div class="area_txt">
              <img class="register_01" src="../assets/register_02.png" alt="" />
            </div>
            <!-- 输入框部分 -->
            <input
              v-model="userSMS"
              class="input_main"
              placeholder="请输入验证码"
            />
            <div class="area_txt1" @click="sendSMS">获取</div>
          </div>

          <div class="cs_tip">
            收不到验证码请联系 <span @click="openCslink">在线客服</span>
          </div>

          <div class="input_box">
            <div class="area_txt">
              <img class="register_01" src="../assets/register_03.png" alt="" />
            </div>
            <!-- 输入框部分 -->
            <input
              v-model="userPassword"
              class="input_main"
              placeholder="请设置6-16位登录密码"
            />
          </div>

          <div class="input_box">
            <div class="area_txt">
              <img class="register_01" src="../assets/register_03.png" alt="" />
            </div>
            <!-- 输入框部分 -->
            <input
              v-model="userPassword_two"
              class="input_main"
              placeholder="请确认登录密码"
            />
          </div>
          <div class="input_box">
            <div class="area_txt">
              <img class="register_01" src="../assets/register_04.png" alt="" />
            </div>
            <!-- 输入框部分 -->
            <input
              v-model="agentCode"
              class="input_main"
              placeholder="营业部邀请码"
            />
          </div>

          <div class="input_box">
            <div class="area_txt">
              <img class="register_01" src="../assets/register_04.png" alt="" />
            </div>
            <!-- 输入框部分 -->
            <input
              v-model="referralCode"
              class="input_main"
              placeholder="推荐吗"
            />
          </div>

          <div class="cs_tip" style="margin-bottom: 10px">
            <!-- 注册即代表您已阅读并同意 <span>《配资协议》</span> -->
          </div>

          <div class="reg_btn" @click="regiser">下一步</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import main_01 from "../assets/main_01.png";
export default {
  name: "",
  data() {
    return {
      main_01: main_01,
      showPopover: false,
      showLL: false,
      showRegister: false,
      account: "",
      HomeData: {},
      sendSMSbuttonText: "发送",
      smsTimer: null,
      countdown: 0,
      phone: "",
      userSMS: "",
      userPassword_two: "",
      userPassword: "",
      agentCode: "",
      referralCode: "",
      Links: "",
      bannerList: [],
      logo:"",
    };
  },
  methods: {
    Donw(num) {
      var link = "";
      if (num == 1) {
        // ios
        link = this.Links.iosDownloadLink;
        window.open(link);
      } else if (num == 2) {
        // ios mobile
        link = this.Links.iphoneH5DecDoc;
        window.location.assign(link);
      } else if (num == 3) {
        // 安卓
        link = this.Links.androidDownloadLink;
        window.open(link);
      } else {
        // pc
        link = this.Links.winDownloadLink;
        window.location.assign(link);
      }
    },

    getBannerByPlat() {
      this.$api.getBannerByPlat().then((res) => {});
    },
    sendSMS() {
      if (!this.phone) {
        Toast("请输入手机号");
        return;
      }
      this.countdown = 60;

      let opts = {
        phone: this.phone,
      };
      try {
        this.$api.sendLoginSms(opts).then((res) => {
          if (res.data && res.data.status === 0) {
            Toast("发送简讯成功");
          } else {
            Toast("发送简讯失败 请稍后再试");
            return;
          }
        });
      } catch (error) {
        Toast("发送简讯失败 请稍后再试");
      }

      this.smsTimer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
          this.sendSMSbuttonText = this.countdown + "s";
        } else {
          clearInterval(this.smsTimer);
          this.smsTimer = null;
          this.sendSMSbuttonText = "发送";
        }
      }, 1000);
    },
    regiser() {
      if (!this.phone) {
        Toast("请输入手机号");
        return;
      }
      if (!this.userSMS) {
        Toast("请输入验证码");
        return;
      }
      if (!this.userPassword) {
        Toast("请输入密码");
        return;
      }

      if (!this.userPassword_two) {
        Toast("请输入确认密码");
        return;
      }

      if (this.userPassword_two != this.userPassword) {
        Toast("两次密码不一致");
        return;
      }

      if (!this.userPassword_two) {
        Toast("请输入确认密码");
        return;
      }
      if (!this.agentCode) {
        Toast("请输入邀请码");
        return;
      }

      if (!this.referralCode) {
        Toast("请输入推荐码");
        return;
      }
      let opts = {
        phone: this.phone,
        yzmCode: "6666",
        userPwd: this.userPassword,
        agentCode: this.agentCode,
        userSMS: this.userSMS,
        referralCode: this.referralCode,
      };
      this.$api.register(opts).then((res) => {
        if (res && res.data.status == 0) {
          Toast("注册成功");
          window.open("https://wap.fzyp001.vip");
        } else {
          Toast("注册失败");
        }
      });
    },
    getSiteHomePageSetting() {
      this.$api.getSiteHomePageSetting().then((res) => {
        if (res.data.status == 0) {
          try {
            this.HomeData = res.data.data.homePage;
            this.Links = res.data.data.downloadLink;
            var banners = this.HomeData.bannerList;
            this.bannerList = banners.split(",");
            this.logo = this.HomeData.logo;

            document.title = this.HomeData.siteName;

            // 获取现有的 link 元素
            var favicon =
              document.querySelector("link[rel='icon']") ||
              document.createElement("link");

            // 设置 rel、type 和 href 属性
            favicon.rel = "icon";
            favicon.type = "image/x-icon";
            favicon.href = this.HomeData.icon;

            // 将 link 元素添加到 head 中
            document.head.appendChild(favicon);
          } catch (error) {}
        }
      });
    },
    openCslink() {
      if (this.HomeData.customerServiceLink) {
        window.open(this.HomeData.customerServiceLink);
      }
    },
  },
  mounted() {
    //dom 准备完毕
  },
  created() {
    //应用创建
    // this.getBannerByPlat();
    this.getSiteHomePageSetting();
  },
};
</script>
<style>
.my-swipe {
  /* width: 100%; */
  /* height: 600px; */
}
.van-notice-bar__content {
  font-weight: 700 !important;
}

.van-icon__image {
  width: 32px !important;
  height: 32px !important;
}
</style>
<style lang="less" scoped>
.home {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 70px;
  background: white;

  .home_details {
    display: flex;
    flex-direction: column;

    .ac_header_box {
      background: #f7b329 !important;
      height: 50px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: left;
      font-size: 18px;
      color: black;
    }

    .content {
      padding: 16px 20px;

      .input_box {
        background-color: white;
        border-radius: 8px;
        margin-top: 10px;
        height: 45px;
        border: 1px solid rgb(195, 195, 195);
        display: flex;
        align-items: center;
        justify-content: left;

        .area_txt {
          height: 100%;
          background: rgb(216, 216, 216);
          border-radius: 8px 0 0 8px;

          .register_01 {
            height: 44px;
            padding: 9px;
          }
        }

        .area_txt1 {
          width: 80px;
          text-align: center;
          line-height: 45px;
          color: #f7b329;
          font-size: 15px;
        }

        .input_main {
          width: 100%;
          height: 100%;
          border: 0px;
          outline: none;
          background: none;
          border-radius: 8px;
          color: black;
          text-align: left;
          font-size: 16px;
          padding-left: 10px;

          ::-webkit-input-placeholder {
            color: black;
            font-size: 16px;
          }
        }
      }

      .cs_tip {
        font-size: 12px;
        color: #9a9a9a;
        margin-top: 10px;

        span {
          color: #f7b329;
        }
      }

      .reg_btn {
        width: 100%;
        height: 40px;
        background: #f7b329;
        font-size: 16px;
        color: #000;
        text-align: center;
        line-height: 40px;
      }
    }
  }

  .swip_box {
    position: relative;

    .isRegister_box {
      position: absolute;
      top: 100px;
      right: 18%;
      width: 304px;
      background: #f8f8f8;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .is_txt_one {
        font-size: 20px;
        color: #231815;
        margin: 20px 0;
      }

      .is_txt_two {
        font-size: 24px;
        color: #231815;
      }

      .is_txt_three {
        font-size: 16px;
        color: #9a9a9a;
        margin-top: 20px;
      }

      .re_now {
        padding: 30px 0;

        .re_btn {
          width: 266px;
          margin: 0 auto;
          height: 44px;
          line-height: 44px;
          background: #f7b329;
          border-radius: 5px;
          font-size: 15px;
          font-weight: 500;
          color: #000;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  .home_main_box_three {
    width: 100%;
    background: white;

    .support_box {
      width: 1200px;
      margin: 0px auto;

      .main_014 {
        width: 100%;
        margin: 80px 0;
      }
    }
  }

  // margin-top: 100px;
  .home_main_box_two {
    width: 100%;
    background: #f8f8f8;

    .trade_box {
      width: 1200px;
      margin: 0px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 60px 0;

      .trade_title {
        font-size: 40px;
        font-weight: 500;
        color: #231815;
      }

      .trade_tip {
        font-size: 18px;
        font-weight: 500;
        color: #231815;
      }

      .trade_download_box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        margin-top: 60px;

        .left_icon {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .main_09 {
          width: 692px;
        }

        .download_images_box {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .images_box {
            height: 182px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .down_icon {
              width: 130px;
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }

  .notice_container {
    width: 100%;
    background: #f8f8f8;

    .notice_box {
      width: 1200px;
      margin: 0px auto;
      margin-top: -5px;
      position: relative;

      .main_02 {
        width: 32px;
        height: 32px;
        position: absolute;
        right: -32px;
        top: 4px;
      }
    }
  }

  .home_main_box {
    width: 1200px;
    margin: 0px auto;

    .pcinfo_box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .info_item_box {
        width: 33%;
        padding: 0 40px;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .info_icon {
          width: 80px;
          height: 80px;
        }

        .info_title {
          font-size: 18px;
          color: #231815;
          font-weight: 500;
          margin: 10px 0;
        }

        .content {
          font-size: 16px;
          color: #9a9a9a;
          font-weight: 500;
          text-align: center;
        }
      }
    }

    .numinfo_box {
      width: 100%;
      height: 258px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .numinfo_item {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .nun_txt {
          font-size: 35px;
          color: #444;
          font-weight: 500;
        }

        .num_title {
          font-size: 16px;
          color: #9a9a9a;
        }
      }

      .num_line {
        width: 1px;
        height: 50px;
        background: #e5e5e5;
      }
    }
  }
}
</style>