<template>
  <div id="app" class="app">
    <div class="pc_header_box">
      <div class="header_content">
        <img class="icon_01" :src="logo" alt="" />
        <div class="tabs_box">
          <div class="tablist_box">
            <div
              :class="{ tab_item: true, tab_item_select: typeRouter('Home') }"
              @click="toLinkPage('/', 0)"
            >
              网站首页
            </div>
            <!-- <div :class="{ tab_item: true, tab_item_select: navIndex === 1 }">
              在线交易
            </div> -->
            <div
              :class="{ tab_item: true, tab_item_select: typeRouter('Download') }"
              @click="toLinkPage('/download', 2)"
            >
              下载App
            </div>
            <div
              :class="{ tab_item: true, tab_item_select: typeRouter('Activity') }"
              @click="toLinkPage('/activity', 3)"
            >
              活动中心
            </div>
            <div
              :class="{ tab_item: true, tab_item_select: typeRouter('Help') }"
              @click="toLinkPage('/help', 4)"
            >
              帮助中心
            </div>
            <div
              :class="{ tab_item: true, tab_item_select: navIndex === 5 }"
              @click="openCslink"
            >
              联系客服
            </div>
            <div
              :class="{ tab_item: true, tab_item_select: typeRouter('About') }"
              @click="toLinkPage('/about', 6)"
            >
              关于我们
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view />
    <Bottom />

    <div class="cs_icon"  @click="openCslink"
>
      <img class="customer_01" src="./assets/customer_01.png" alt="" />
    </div>
  </div>
</template>

<script>
import "./assets/css/pages.css";
import Bottom from "@/views/Bottom.vue";
export default {
  name: "App",
  components: {
    Bottom,
  },

  data() {
    return {
      //数据信息
      navIndex: 0,
      homeData: {},
      logo:"",
    };
  },
  methods: {
    typeRouter(text){
      if(this.$route.name == text){
        return true;
      }
      return false;
    },
    toLinkPage(url, index) {
      this.$router.push(url);
      this.navIndex = index;
    },
    openCslink() {
      if (this.homeData.customerServiceLink) {
        window.open(this.homeData.customerServiceLink);
      }
    },
    getSiteHomePageSetting() {
      this.$api.getSiteHomePageSetting().then((res) => {
        if (res.data.status == 0) {
          try {
            this.homeData = res.data.data.homePage;
            this.logo = this.homeData.logo;
            localStorage.setItem("data", JSON.stringify(res.data.data.homePage));
          } catch {}
        }
      });
    },
  },
  mounted() {},
  created() {
    this.getSiteHomePageSetting();
  },
};
</script>
<style>
@import url("./assets/font/font.css");
@import url("./assets/css/pages.css");
</style>
<style lang="less" scoped>
.cs_icon {
  position: fixed;
  top: 70%;
  right: 50px;
  .customer_01 {
    width: 90px;
  }
}
.pc_header_box {
  width: 100%;
  background-color: white;

  .header_content {
    width: 1200px;
    height: 100px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon_01 {
      height: 63px;
    }

    .tabs_box {
      .tablist_box {
        display: flex;
        align-items: center;
        justify-content: center;

        .tab_item {
          font-size: 15px;
          color: #231815;
          width: 100px;
          font-weight: 500;
          cursor: pointer;
        }
        .tab_item_select {
          font-size: 15px;
          color: #f7b329;
          width: 100px;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
