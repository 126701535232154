<template>
  <div class="bottom">
    <div class="bottom_main_box_one">
      <div class="top_main_container">
        <div class="bottom_tabs_box">
          <div class="tabsList_box">
            <div class="tab_title" >新手教程</div>
            <div class="tab_lable" @click="pushPeopleMsg(0)">如何交易</div>
            <div class="tab_lable" @click="pushPeopleMsg(1)">如何提现</div>
            <div class="tab_lable" @click="pushPeopleMsg(2)">如何充值</div>
            <div class="tab_lable" @click="pushPeopleMsg(3)">如何申请合约</div>
            <div class="tab_lable" @click="pushPeopleMsg(4)">如何注册</div>
            <!-- <div class="tab_lable" @click="pushPeopleMsg(4)">查看更多</div> -->
          </div>
          <div class="tabsList_box">
            <div class="tab_title">帮助中心</div>
            <div class="tab_lable" @click="pushPeopleMsg(5)">常见问题</div>
            ]
          </div>
          <div class="tabsList_box">
            <div class="tab_title">关于我们</div>
            <div class="tab_lable"  @click="pushAbout(0)">企业文化</div>
            <div class="tab_lable"  @click="pushAbout(1)">公司简介</div>
            <div class="tab_lable"  @click="pushAbout(2)">办公环境</div>
            <div class="tab_lable"  @click="pushAbout(3)">联系我们</div>
            <div class="tab_lable"  @click="pushAbout(4)">资质证书</div>

            <!-- <div class="tab_lable">查看更多</div> -->
          </div>
          <div class="tabsList_box">
            <div class="tab_title">用户协议</div>
            <div class="tab_lable" @click="toProtocol(1)">隐私政策</div>
            <div class="tab_lable" @click="toProtocol(2)">交易规则</div>
            <div class="tab_lable" @click="toProtocol(3)">配资协议</div>
          </div>
        </div>

        <!-- <div class="down_box">
          <div class="img_box">
            <img class="mian_013" src="../assets/main_013.png" alt="" />
          </div>
          <div class="down_title">打开网页</div>
        </div> -->
      </div>
      <div class="norice_title">投资有风险 入市需谨慎</div>
    </div>
    <!-- 客服 位置 -->
    <div class="bottom_main_box_two">
      <div class="cs_box">
        <div class="csList">
          <div class="csitem">
            客服热线: <span class="cscontent">{{ HomeData.customerServicePhone  }}</span>
          </div>
        </div>
        <div class="csList">
          <div class="csitem">
            客服QQ: <span class="cscontent">{{ HomeData.customerServiceQq  }}</span>
          </div>
          <div class="csitem">
            泡泡客服: <span class="cscontent">{{ HomeData.customerServicePaoPao  }}</span>
          </div>
        </div>
        <div class="csList">
          <div class="csitem">在线时间:{{ HomeData.onlineTime }}</div>
          <div class="csitem">
            办公地址: {{ HomeData.officeLocation }}
          </div>
        </div>
      </div>
    </div>
    <!-- 网站 标签 -->
    <div class="bottom_main_box_three">
      <div class="tags_box">
        <img class="main_tag_01" src="../assets/main_tag_01.jpg" alt="" />
        <img class="main_tag_01" src="../assets/main_tag_02.jpg" alt="" />
        <img class="main_tag_01" src="../assets/main_tag_03.jpg" alt="" />
        <img class="main_tag_01" src="../assets/main_tag_04.png" alt="" />
        <img class="main_tag_01" src="../assets/main_tag_05.png" alt="" />
        <img class="main_tag_01" src="../assets/main_tag_06.png" alt="" />
        <img class="main_tag_01" src="../assets/main_tag_07.png" alt="" />
        <img class="main_tag_01" src="../assets/main_tag_08.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import brands_01 from "../assets/banner_01.png";
import QRCode from "qrcodejs2";

export default {
  name: "",
  data() {
    return {
        HomeData:{},
        downloadLink:{},
    };
  },
  methods: {
    // qrcode() {
    //   document.getElementById("qrcode").innerHTML = "";
    //   new QRCode("qrcode", {
    //     width: 100,
    //     height: 100,
    //     text: this.downloadLink, // 二维码地址
    //     colorDark: "#000",
    //     colorLight: "#fff",
    //   });
    // },
    getSiteHomePageSetting(){
    this.$api.getSiteHomePageSetting().then((res)=>{
      if(res.data.status == 0){
        try{
            this.HomeData = res.data.data.homePage;
            this.downloadLink = res.data.data.downloadLink;
        }catch{
          
        }
      }
    })
   },
    toProtocol(index) {
      this.$router.push({
        path: "/protocol",
       
      });
      setTimeout(() => {
        this.$bus.$emit("protocol", index);

      }, 150);

    },
    pushPeopleMsg(index) {
      this.$router.push({
        path: "/help",
      });
      setTimeout(() => {
        this.$bus.$emit("help", index);

      }, 150);

      
    },
    pushAbout(index) {
      this.$router.push({
        path: "/about",
      });
      setTimeout(() => {
        this.$bus.$emit("about", index);

      }, 150);

    },
    
  },

  mounted() {
    //dom 准备完毕
  },
  created() {
    //应用创建
    this.getSiteHomePageSetting();
  },
};
</script>

<style lang="less" scoped>
.bottom {
  width: 100%;
  background: #181a20;

  .bottom_main_box_three {
    width: 100%;
    background-color: #222;
    padding: 20px 0;
    .tags_box {
      display: flex;
      width: 1200px;
      margin: 0 auto;

      .main_tag_01 {
        height: 47px;
        margin: 0 6px;
      }
    }
  }

  .bottom_main_box_two {
    width: 100%;
    background: #1b1b1b;
    border-top: 1px solid #2c2c2c;
    border-bottom: 1px solid #2c2c2c;

    .cs_box {
      width: 1200px;
      margin: 40px auto;
      display: flex;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .csList {
        flex: 1;
        text-align: left;

        .csitem {
          font-size: 15px;
          color: #bdbdbd;
          font-weight: 500;

          .cscontent {
            color: rgb(247, 179, 41);
          }
        }
      }
    }
  }

  .bottom_main_box_one {
    width: 1200px;
    margin: 0 auto;
    padding-top: 70px;
    padding-bottom: 20px;

    .top_main_container {
      display: flex;
      align-items: center;
      flex-direction: row;

      .bottom_tabs_box {
        display: flex;

        .tabsList_box {
          width: 200px;

          .tab_title {
            font-size: 18px;
            color: white;
            margin-bottom: 36px;
          }

          .tab_lable {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 20px;
            cursor: pointer;
            color: #bdbdbd;
          }
        }
      }

      .down_box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-direction: column;

        .img_box {
          width: 110px;
          height: 110px;
          display: flex;
          background-color: white;
          align-items: center;
          justify-content: center;

          .mian_013 {
            width: 100px;
            height: 100px;
          }
        }

        .down_title {
          font-size: 15px;
          color: #bdbdbd;
          font-weight: 500;
          margin-top: 10px;
        }
      }
    }

    .norice_title {
      font-size: 15px;
      color: #bdbdbd;
      font-weight: 500;
      margin-left: 30px;
    }
  }
}
</style>