import { requests } from './request';

export default {

    // 注册
    UserRegister(data) {
        return requests.post('/api/user/register', data);
    },
    // 注册
    getSiteHomePageSetting(data) {
        console.log(data);
        return requests.post('/api/site/getSiteConfigAdvance.do', data);
    },
    // 注册
    getBannerByPlat(data) {
        return requests.post('/api/site/getBannerByPlat.do', data);
    },
    sendLoginSms(data) {
        return  requests.post('/api/sms/sendLoginSms.do', data)
    },

    register(data) {
        return  requests.post('/api/user/reg.do', data)
    }

};