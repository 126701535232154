import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import i18n from "./lang/index";
import { Popup, Loading,Icon,Empty } from 'vant';
import { Collapse,NoticeBar ,Step, Steps, CollapseItem,Picker ,Grid, GridItem,Search,Popover,Checkbox, CheckboxGroup,NavBar, Tabbar,TabbarItem,Swipe, SwipeItem,Switch,DatetimePicker} from 'vant';
import VueClipboard from 'vue-clipboard2';
import "@/assets/text/text.css";


// import iView from 'view-design';
import 'vant/lib/index.css';

import api from "./api/request_list";

import env from "../env";
Vue.prototype.$env = env;

Vue.use(Popover);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Loading);
Vue.use(VueClipboard);
Vue.use(Empty);
Vue.use(Icon);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Search);
Vue.use(NavBar);
Vue.use(TabbarItem);
Vue.use(Tabbar);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Switch);
Vue.use(DatetimePicker);
Vue.use(NoticeBar);






Vue.prototype.$api = api;
Vue.config.productionTip = false
Vue.prototype._i18n = i18n;

var vues =  new Vue({
  router,
  i18n,
  render: h => h(App),
});

Vue.prototype.$bus = vues;
vues.$mount('#app');
export default vues

