<template>
  <div class="protocol">
    <div class="protocol_header_box">用户协议</div>
    <div class="protocol_main_container">
      <div class="protocol_box">
        <!-- 切换的tabs -->
        <div class="ac_tabs_box">
          <div class="tab_list">
            <div
              @click="changeIndex(1)"
              :class="{ tab_item: true, tab_item_select: index === 1 }"
            >
              隐私政策
            </div>
            <div
              @click="changeIndex(2)"
              :class="{ tab_item: true, tab_item_select: index === 2 }"
            >
              交易规则
            </div>
            <div
              @click="changeIndex(3)"
              :class="{ tab_item: true, tab_item_select: index === 3 }"
            >
              配资协议
            </div>
          </div>
        </div>
        <div class="content_main">
          <div class="proOne_box" v-if="index == 1">
            <div class="one_title">隐私政策</div>
            <div
              class="one_info"
              style="
                margin-top: 30px;
                font-size: 16px;
                color: black;
                line-height: 1.5;
              "
            >
              关于{{ HomeData.siteName || "" }}私募基金管理有限公司,在您注册成为网站会员之前，请您务必认真阅读和理解《网站用户注册协议》（以下简称：协议）中所有的条款。您须完全同意协议中所有的条款，才可以注册成为本网站的会员，使用里面的服务。您在网站的注册和操作均将被视为是您对协议所有条款及内容的自愿接受。<br /><br /><br />
              一、声明<br />

              （一）网站内在线产品（除保证金、盈利）的所有权归{{ HomeData.siteName || "" }}优配所有。<br />

              （二）您在网站进行注册时，一旦点击“我接受”按钮，即表示为您已自愿接受协议中所有的条款和内容。<br />

              （三）同意并认可此账号注册后，如操作导致平台方提供资金风险过高，经平台核实后，将全权授权委托平台方对此账号进行操作交易。<br />

              （四）协议条款的效力范围仅限于本网站，您在网站的行为均受协议的约束。<br />

              （五）您使用网站服务的行为，即被视为您已知悉本网站的相关公告并同意。<br />

              （六）本网站有权依据最新股市政策更改协议条款，将会发布网站通告，如有疑问可咨询在线客服。<br />

              （七）本网站有权修改、暂停网站部分或全部的服务，将会发布网站通告，如用户方操作失误导致的损失，本网站不承担来自用户方及第三方的任何责任。<br />

              （八）本网站提供免费注册服务，您的注册均是自愿行为，注册成功后，您可以得到网站更加完善的服务。<br />

              （九）您注册成为会员后账户和密码如有遗失，不会影响到您已办理成功业务的效力，本网站可恢复您的注册账户及相关信息但不承担除此以外的其它任何责任。<br />

              二、用户管理<br />

              （一）您在本网站的所有行为都须符合中国的法律法规，您不得利用本网站提供的服务制作、复制、发布、传播以下信息：<br />

              1、反对宪法基本原则的。<br />

              2、危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一的。<br />

              3、损害国家荣誉和利益的。<br />

              4、煽动民族仇恨、民族歧视、破坏民族团结的。<br />

              5、破坏国家宗教政策，宣扬邪教和封建迷信的。<br />

              6、散布谣言、扰乱社会秩序、破坏社会稳定的。<br />

              7、散布淫秽、色情、赌博、暴力、凶杀、恐怖内容或者教唆犯罪的。<br />

              8、侮辱或者诽谤他人，侵害他人合法权益的。<br />

              9、以及法律、法规禁止的其他内容。<br />

              （二）您在本网站的行为，还必须符合其它国家和地区的法律规定以及国际法的有关规定。<br />

              （三）不得利用本网站从事以下活动：<br />

              1、未经允许，进入他人计算机信息网络或者使用他人计算机信息网络的资源。<br />

              2、未经允许，对他人计算机信息网络的功能进行删除、修改或增加。<br />

              3、未经允许，对他人计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加。<br />

              4、制作、故意传播计算机病毒等破坏性程序的。<br />

              5、其他危害计算机信息网络安全的行为。<br />

              （四）遵守本网站其他规定和程序：<br />

              1、您对自己在本网站中的行为和操作承担全部责任。<br />

              2、您承担责任的形式包括但不仅限于，对受到侵害者进行赔偿、在本网站首先承担了因您的行为导致的行政处罚或侵权损害赔偿责任后，您应给予本网站的等额赔偿。<br />

              3、如果本网站发现您传输的信息含有本协议所规定的内容，本网站有权在不通知您的情况下采取包括但不仅限于向国家有关机关报告、保存有关记录、删除该内容及链接地址、关闭服务器、暂停您账号的操作权限、停止向您提供服务等措施。<br />

              三、注册会员权利和义务<br />

              （一）注册会员有权用本网站提供的服务功能。<br />

              （二）注册会员同意遵守包括但不仅限于《中华人民共和国保守国家秘密法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《互联网电子公告服务管理规定》、《互联网信息服务管理办法》等在内的法律、法规。<br />

              （三）您注册时有义务提供完整、真实、的个人信息，信息如有变更，应及时更新。<br />

              （四）您成为注册会员须妥善保管用户名和密码，用户登录后进行的一切活动均视为是您本人的行为和意愿，您负全部责任。<br />

              （五）您在使用本网站服务时，同意且接受本网站提供的各类信息服务。<br />

              （六）您使用本网站时，禁止有以下行为：<br />

              1、上载、张贴、发送电子邮件或以其他方式传送含有违反国家法律、法规的信息或资料，这些资料包括但不仅限于资讯、资料、文字、软件、音乐、照片、图形、等（下同）。<br />

              2、散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的资料。<br />

              3、冒充任何个人或机构，或以虚伪不实的方式误导他人以为其与任何人或任何机构有关。<br />

              4、通过本网站干扰、破坏或限制他人计算机软件、硬件或通讯设备功能的行为。<br />

              5、通过本网站跟踪或以其他方式骚扰他人。<br />

              四、用户隐私<br />

              我们承诺，对您个人的信息和隐私的安全承担保密义务。未经您授权或同意，本网站不会将您的个人资料信息泄露给第三方，但以下情况除外，且本网站不承担任何责任：<br />

              （一）政府单位按照中华人民共和国的法律、行政法规、部门规章、司法解释等规范性法律文件（统称“法律法规”），要求本网站提供的。<br />

              （二）由于您将用户和密码告知或泄露给他人，由此导致的个人资料泄露。<br />

              （三）包括但不仅限于黑客攻击、计算机病毒侵入或发作、政府管制等不可抗力而造成的用户个人资料泄露、丢失、被盗用或被篡改等。<br />

              （四）为免除他人正在遭受威胁到其生命、身体或财产等方面的危险，所采取的措施。<br />

              （五）本网站会与其他网站链接，但不对其他网站的隐私政策及内容负责。<br />

              （六）此外，您若发现有任何非法使用您的用户账号或安全漏洞的情况，应立即通告本网站。<br />

              （七）由于您自身的疏忽、大意等过错所导致的。<br />

              （八）您在本网站的有关记录有可能成为您违反法律法规和本协议的证据。<br />

              五、知识产权<br />

              本网站所有的域名、商号、商标、文字、视像及声音内容、图形及图像均受有关所有权和知识产权法律的保护，未经本网站事先以书面明确允许，任何个人或单位，均不得进行复制和使用。<br />

              六、法律适用<br />

              （一）协议由本网站的所有人负责修订，并通过本网站公布，您的注册行为即被视为您自愿接受协议的全部条款，受其约束。<br />

              （二）协议的生效、履行、解释及争议的解决均适用中华人民共和国法律。<br />

              （三）协议的条款如与法律相抵触，本网站可进行重新解析，而其他条款则保持对您产生法律效力和约束。<br />
            </div>
          </div>
          <div class="proTwo_box" v-if="index == 2">
            <div class="one_title">交易规则</div>
            <div
              class="one_info"
              style="
                margin-top: 30px;
                font-size: 16px;
                color: black;
                line-height: 1.5;
              "
            >
              平台交易时间：9:30-11:30 13:00-14:57<br />
              1.全程支付账户管理费（不包含交易印花税、过户费和佣金），无其他任何费用。<br />
              2.投资本金：用于投资股票的资金本金，最低500元~500万不等。<br />
              3.管理费计算：按天合约，以2个交易日计算；按周合约，以7个自然日计算；按月合约，以30个自然日计算。<br />
              4.开始交易时间：交易日当天14:50之前的申请于当日生效（当天开始收取合约管理费），交易日当天14：50后的申请于下个交易日生效。<br />
              5.穿仓免责：合约本金全额亏损，超出本金亏损时，则视为穿仓！我平台承诺超过本金的亏损均由我平台承担，客户不承担穿仓责任。<br />
              6.节假日利息退回：有效合约时间跨度包含国家法定节假日时，可向客服申请对应天数利息退回，所有退回利息均以免息券形式退回。<br />
              7.投资本金：用于投资股票的基本资金，最低500元-500万不等。<br />
              8.亏损警戒线：亏损掉杠杆保证金的50%为警戒线。建议及时追加保证金。<br />
              9.亏损平仓线：亏损掉杠杆保证金的80%为平仓线。当总操盘资金低于止损线以下时，我们将有权把您的股票进行平仓，为避免平仓发生，请时刻关注本金是否充足。<br />
              10.强平执行原则：当合约触发强平指令时，系统按照优先成交的原则进行挂单，不能保证卖出价格，但是请您放心的是成交价格是当时的市价成交，如因其它不可抗力因素不能卖出，系统会持续执行卖出申请，直至卖掉股票，期间造成的损失全由用户承担。<br />
              11.竞价挂单细则：根据券商执行规则，具体如下：<br />
              ●9:15—9:20这五分钟开放式集合竞价可以委托买进和卖出的单子；<br />
              ●9:25—9:30这五分钟不叫集合竞价时间，电脑这五分钟可接收买和卖委托，也可接收撤单，这五分钟电脑不处理，9:30分后统一处理买卖委托，按照挂单量（单量大优先）、挂单时间（先后顺序）进行成交；<br />
              注：集合竞价期间提交的挂单，可能会成交，但不是一定会成交。通道竞价成交速度为不可控因素，请客户勿要以未成交为借口，向平台索赔以此造成的莫须有损失。<br />
              12.挂单，撤单规则：<br />
              ●交易日当天未成交的挂单，系统会在14:55-15:00之间自动撤单<br />
              ●14:55停止交易，会员可以挂单，但是不会成交，下一个交易日开盘后生效；<br />
              13.停牌持仓未卖出，本质上是用户个人行为，风险自行承担，用户须继续持有停牌股票。停牌时期管理费会自动续费计算。如需转让，自停牌日起两日内须向平台客服人员提交申请，以停牌前收盘价结算给平台，并由平台继续持有，复牌时盈亏与用户无关。但是平台有权利拒绝收回所有个股，特别是异常停牌的高风险的个股。请注意关注个股停牌情况，建议在停牌前卖出。<br />
              14.股票配资限制购买的股票有哪些？<br />
              （一）不得购买权证类可以T+0交易的证券;<br />
              （二）不得买入当天禁买的票，禁买包括但不仅限于:ST、*ST、SST、*SST、分级基金等被证券交易所特别处理的;<br />
              （三）不得购买价格低于3元的;不得长期短线交易；或者交易低价股盈利视为无效，我们有权解除合约退还本金。<br />
              （四）已发布停牌、退市公告或有潜在退市风险;<br />
              （五）有可能导致结算日无法正常卖出或亏损超过保证金;<br />
              （六）不得购买上市30日以内的新股（或复牌首日）等当日不设涨跌停板限制;<br />
              （七）不得进行坐庄、对敲、接盘、大宗交易、内幕信息等违反交易法律法规及证券公司规定的交易。<br />
              （八）大涨大跌的情况，涨幅超过8个点不能购买，科创，创业，涨幅超过16个点不能购买。<br /><br />

              15.创业板，科创版的限制：<br />
              ●操盘金在100000及以上的合约可购买创业板；（注）本金一万扩大10倍可开通创业版<br />
              ●操盘金在500000及以上的合约可购买科创版的股票；（注）本金五万扩大10倍可开通科创版<br />

              股市有风险，投资需谨慎。
            </div>
          </div>
          <div class="proThree_box" v-if="index == 3">
            <div class="one_title">配资协议</div>
            <div
              class="one_info"
              style="
                margin-top: 30px;
                font-size: 16px;
                color: black;
                line-height: 1.5;
              "
            >
              特别提示：<br />

              在使用{{ HomeData.siteName || "" }}服务之前，您应当认真阅读并遵守《{{ HomeData.siteName || "" }}服务协议》（以下简称"本协议"），请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款。如您对协议有任何疑问的，应向{{ HomeData.siteName || "" }}客服咨询。当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您按照激活页面提示填写信息、阅读并同意本协议且完成全部激活程序后，或您以其他{{ HomeData.siteName || "" }}允许的方式实际使用{{ HomeData.siteName || "" }}服务时，即表示您已充分阅读、理解并接受本协议的全部内容，并与{{ HomeData.siteName || "" }}达成协议。您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得{{ HomeData.siteName || "" }}对您问询的解答等理由，主张本协议无效，或要求撤销本协议。<br />

              一、协议范围<br />

              1、本协议由您与{{ HomeData.siteName || "" }}的经营者共同缔结，本协议具有合同效力。有关{{ HomeData.siteName || "" }}经营者的信息请查看{{ HomeData.siteName || "" }}首页底部公布的公司信息和证照信息。<br />

              2、除另行明确声明外，{{ HomeData.siteName || "" }}服务包含任何{{ HomeData.siteName || "" }}提供的基于互联网以及移动网的相关服务，且均受本协议约束。如果您不同意本协议的约定，您应立即停止注册/激活程序或停止使用{{ HomeData.siteName || "" }}服务。<br />

              3、本协议内容包括协议正文及所有{{ HomeData.siteName || "" }}已经发布或将来可能发布的各类规则、公告或通知均为本协议不可分割的组成部分，与协议正文具有同等法律效力。<br />

              4、{{ HomeData.siteName || "" }}有权根据需要不时地制订、修改本协议及/或各类规则，并以网站公示的方式进行变更公告，无需另行单独通知您。变更后的协议和规则一经在网站公布后，立即或在公告明确的特定时间自动生效。若您在前述变更公告后继续使用{{ HomeData.siteName || "" }}服务的，即表示您已经阅读、理解并接受经修订的协议和规则。若您不同意相关变更，应当立即停止使用{{ HomeData.siteName || "" }}服务。<br />

              二、使用规则<br />

              1、您确认，在您完成注册程序或以其他{{ HomeData.siteName || "" }}允许的方式实际使用{{ HomeData.siteName || "" }}服务时，您应当是具备完全民事行为能权利能力和完全民事力的自然人、法人或其他组织。若您不具备前述主体资格，则您及您的监护人应承担因此而导致的一切后果，且{{ HomeData.siteName || "" }}有权注销或永久冻结您的账户，并向您及您的监护人索偿相应损失。<br />

              2、您注册成功后，{{ HomeData.siteName || "" }}将给予每个注册用户一个用户帐号及相应的密码，该用户帐号和密码由您负责保管；您应当对您帐号进行的所有活动和事件负法律责任。<br />

              3、您不应将您的帐号、密码转让或出借予他人使用。如您发现您的帐号遭他人非法使用，应立即通知{{ HomeData.siteName || "" }}。因黑客行为或您的保管疏忽导致帐号、密码遭他人非法使用，{{ HomeData.siteName || "" }}不承担任何责任。<br />

              4、您承诺不得以任何方式利用{{ HomeData.siteName || "" }}直接或间接从事违反中国法律、以及社会公德的行为，{{ HomeData.siteName || "" }}有权对违反上述承诺的内容和帐户予以删除。<br />

              5、{{ HomeData.siteName || "" }}有权对您使用{{ HomeData.siteName || "" }}的情况进行审查和监督，如您在使用{{ HomeData.siteName || "" }}时违反规定，{{ HomeData.siteName || "" }}或其授权的人有权要求您改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止您使用{{ HomeData.siteName || "" }}的权利）以减轻您不当行为造成的影响。<br />

              6、您注册的帐号昵称如存在违反法律法规或国家政策要求，或侵犯任何第三方合法权益的情况，{{ HomeData.siteName || "" }}有权收回该账号昵称。<br />

              三、免责声明<br />

              1、{{ HomeData.siteName || "" }}不对您发表的内容或评论的正确性进行保证。<br />

              2、您在{{ HomeData.siteName || "" }}发表的内容仅表明您个人的立场和观点，并不代表{{ HomeData.siteName || "" }}的立场或观点。作为内容的发表者，需自行对所发表的内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。{{ HomeData.siteName || "" }}不承担任何法律及连带责任。<br />

              3、鉴于网络服务的特殊性，您同意{{ HomeData.siteName || "" }}有权随时变更、中断或终止部分或全部的网络服务。<br />

              4、您理解，{{ HomeData.siteName || "" }}需要定期或不定期地对提供网络服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护，如因此类情况而造成网络服务在合理时间内的中断，{{ HomeData.siteName || "" }}无需为此承担任何责任，但{{ HomeData.siteName || "" }}应尽可能事先进行通告。<br />

              5、{{ HomeData.siteName || "" }}不保证网站中有关公司商情、股票评析、投资方向等内容的正确性和适用性。您基于上述内容所进行的交易或投资决定，由您承担全部后果和责任。股市有风险，入市须谨慎！<br />

              6、{{ HomeData.siteName || "" }}是一个配资服务平台，汇集了诸多类型的投资者、理财机构，{{ HomeData.siteName || "" }}建议您在使用过程中保护自己的个人隐私与股票账户秘密，不宜将个人隐私或者账户信息透露给其他人员。由此造成的损失，由您自己承担。<br />

              7、本声明未涉及的问题参见国家有关法律法规，当本声明与国家法律法规冲突时，以国家法律法规为准。{{ HomeData.siteName || "" }}之声明以及其修改权、更新权及最终解释权均属{{ HomeData.siteName || "" }}所有。<br />

              四、隐私保护<br />

              保护注册用户隐私是{{ HomeData.siteName || "" }}的一项基本政策，{{ HomeData.siteName || "" }}保证不对外公开或向第三方提供单个注册用户的注册资料及注册用户在使用网络服务时存储在{{ HomeData.siteName || "" }}的非公开内容，但下列情况除外：<br />

              1、事先获得用户的明确授权。<br />

              2、根据有关的法律法规要求。<br />

              3、按照相关政府主管部门的要求。<br />

              4、为维护社会公众的利益。<br />

              5、为维护{{ HomeData.siteName || "" }}的合法权益。<br />

              五、退订政策<br />

              {{ HomeData.siteName || "" }}在提供服务过程中有可能以其他方式向您发送网站相关的服务或者活动信息，这是{{ HomeData.siteName || "" }}提供的服务的组成部分，如您不愿意收到类似信息，可联系{{ HomeData.siteName || "" }}客服完成退订手续。<br />

              六、协议终止<br />

              1、您同意，{{ HomeData.siteName || "" }}有权自行全权决定以任何理由不经事先通知的中止、终止向您提供部分或全部{{ HomeData.siteName || "" }}服务，暂时冻结或永久冻结（注销）您的账户在{{ HomeData.siteName || "" }}的权限，且无须为此向您或任何第三方承担任何责任。<br />

              2、出现以下情况时，{{ HomeData.siteName || "" }}有权直接以注销账户的方式终止本协议，并有权永久冻结（注销）您的账户在{{ HomeData.siteName || "" }}的权限：<br />

              a)您提供的用户信息中的主要内容不真实或不准确或不及时或不完整；<br />

              b)本协议（含规则）变更时，您明示并通知{{ HomeData.siteName || "" }}不愿接受新的服务协议的；<br />

              c)其它{{ HomeData.siteName || "" }}认为应当终止服务的情况。<br />

              七、关于链接<br />

              {{ HomeData.siteName || "" }}平台内容中可能会提供与其他互联网网站或资源的链接。对于上述网站或资源是否可以利用，{{ HomeData.siteName || "" }}不予保证。因使用或依赖上述网站或资源产生的损失或损害，{{ HomeData.siteName || "" }}也不负担任何责任。<br />

              八、法律适用<br />

              1、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国大陆地区法律，如无相关法律规定的，则应参照通用国际商业惯例和/或行业惯例。<br />

              2、本协议及因本协议产生的一切法律关系及纠纷，您与{{ HomeData.siteName || "" }}平台的经营者均同意以被告住所地人民法院为第一审管辖法院。<br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",

  data() {
    return {
      index: 1,
      HomeData:{},
    };
  },
  methods: {
    changeIndex(num) {
      this.index = num;
    },
    getSiteHomePageSetting(){
    this.$api.getSiteHomePageSetting().then((res)=>{
      if(res.data.status == 0){
        try{
          this.HomeData = res.data.data.homePage;
        }catch{
          
        }
      }
    })
   }
  },
  mounted() {
    //dom 准备完毕
    // console.log("1111", this.$route.query.number);
    // this.index = parseInt(this.$route.query.number);
  },
  created() {
    //应用创建
    this.getSiteHomePageSetting();
    this.$bus.$on("protocol", (index) => {
      this.index = index ;
    });
  },
};
</script>
<style>
.van-empty__image {
  height: 220px !important;
  width: 318px !important;
}

.van-empty {
  margin-top: 50px !important;
}

.van-popup__close-icon--top-right {
  color: black !important;
}
</style>
<style lang="less" scoped>
.protocol {
  width: 100%;
  padding-bottom: 70px;
  background: rgb(246, 246, 248);

  .protocol_details {
    display: flex;
    flex-direction: column;
    height: 500px;

    .ac_header_box {
      background: #f7b329 !important;
      height: 50px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: left;
      font-size: 18px;
      color: black;
    }

    .content {
      flex: 1;
      height: 100%;
      overflow: auto;
      overflow-y: auto;
    }

    .foot_box {
      height: 50px;
      border-top: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;

      .fun_btn {
        border-color: #f7b329 !important;
        background-color: #f7b329 !important;
        color: #000 !important;
        padding: 0 66px !important;
        border-radius: 20px !important;
        height: 40px !important;
        line-height: 40px !important;
        font-weight: 500 !important;
        color: #333;
        font-size: 18px;
      }
    }
  }

  .protocol_main_container {
    width: 100%;

    .protocol_box {
      width: 1200px;
      margin: 30px auto;

      .ac_tabs_box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .tab_list {
          display: flex;

          .tab_item {
            height: 56px;
            width: 110px;
            color: #231815;
            font-size: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .tab_item_select {
            height: 56px;
            width: 110px;
            background: #f7b329;
            color: #231815;
            border-radius: 5px;
            font-size: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .protocol_header_box {
    background-image: url("../assets/activity_01.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 255px;
    color: #fff;
    font-size: 40px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content_main {
    width: 100%;
    background: white;
    border-radius: 8px;
    padding: 30px;
    margin-top: 30px;

    .one_title {
      font-size: 20px;
      width: 100%;
      text-align: center;
      padding-bottom: 20px;
      border-bottom: 1px solid rgb(229, 229, 229);
    }
  }
}
</style>