<template>
  <div class="about">
    <div class="about_header_box">
      <div class="txt_box">
        <div class="title_one">为美好生活盈造价值</div>
        <div class="title_two">以专业成就一流的互联网配资平台</div>
      </div>
      <div class="nav_container">
        <div class="nav_box">
          <div class="nav_list">
            <div
              @click="changeNav(1)"
              :class="{ nav_item: true, nav_item_select: navIndex === 1 }"
            >
              企业文化
            </div>
            <div
              @click="changeNav(2)"
              :class="{ nav_item: true, nav_item_select: navIndex === 2 }"
            >
              公司简介
            </div>
            <div
              @click="changeNav(3)"
              :class="{ nav_item: true, nav_item_select: navIndex === 3 }"
            >
              办公环境
            </div>
            <div
              @click="changeNav(4)"
              :class="{ nav_item: true, nav_item_select: navIndex === 4 }"
            >
              联系我们
            </div>
            <div
              @click="changeNav(5)"
              :class="{ nav_item: true, nav_item_select: navIndex === 5 }"
            >
              资质证书
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about_content">
      <div class="aboutOne_box" v-if="navIndex == 1">
        <div class="one_title">企业文化</div>
        <div class="one_lable">企业使命：为美好生活盈造价值</div>
        <div class="one_content">
          企业使命中的“盈”，最早字形见于春秋金文。本意是盛满、充满，引申为多出、有余，用于“盈余”等。又引申为丰满、饱满、圆满无缺、满足、盛多、增长。对幸福生活的追求是推动人类文明进步最持久的力量。在新时代，人民对美好生活的向往更加强烈。公司的使命，就是为人民的美好生活实现贡献价值。
        </div>
        <div class="one_tags_box">
          <div class="tag_item">
            <img class="about_02" src="../assets/about_02.png" alt="" />
            <div class="title">保障资金安全</div>
          </div>
          <div class="tag_item">
            <img class="about_02" src="../assets/about_03.png" alt="" />
            <div class="title">数据信息加密</div>
          </div>
          <div class="tag_item">
            <img class="about_02" src="../assets/about_04.png" alt="" />
            <div class="title">专业服务团队</div>
          </div>
        </div>
      </div>
      <div class="aboutTwo_box" v-if="navIndex == 2">
        <div class="two_title">公司简介</div>
        <img class="about_05" src="../assets/about_05.jpg" alt="" />
        <div class="two_info_title" style="margin-top: 10px">企业简介</div>
        <div class="two_info_content">
          {{ HomeData.siteName || "" }}私募基金管理有限公司<br />
          科技创新使投资者能随时随地参与沪深股杠杆式投资等交易市场。<br />
          {{ HomeData.siteName || "" }}优配致力于创造更简易的交易软件、安全的投资平台，让投资变得更轻松、简单、智能。<br />
          自主研发的交易平台，让开户更便捷、费率更低、使用体验更好、更加本地化，为全球华人投资者提供一站式全方位的投资服务平台。
        </div>
        <div class="two_info_title" style="margin-top: 40px">服务定位</div>
        <div class="two_info_content">
          知名品牌配资平台，致力于为用户提供一流投资体验!
        </div>
        <div class="two_info_title" style="margin-top: 20px">管理团队</div>
        <div class="two_info_content">
          {{ HomeData.siteName || "" }}优配的管理团队拥有多年国际化金融市场经验和互联网产品技术背景，管理团队主要来自于证券、银行、IT等行业，具备证券、金融及私募领域从业经验。
        </div>
        <div class="two_info_title" style="margin-top: 20px">企业使命</div>
        <div class="two_info_content">
          安全金融、卓越体验，满足投资者的金融理财需求，帮助客户资产财富快速增长！
        </div>
        <div class="two_info_title" style="margin-top: 20px">核心价值观</div>
        <div class="two_info_content">
          诚信：坚守诚信原则，重视职业操守，加强互信关系，树立领导品牌。<br />
          专业：整合有效资源，借鉴成功案例，提高专业水平，打造精英团队。<br />
          热情：保持工作热情，积极面对挑战，敢于攀登高峰，追求卓越品质。<br />
        </div>
      </div>

      <div class="aboutThree_box" v-if="navIndex == 3">
        <div class="three_titl">办公环境</div>
        <div v-html="aboutUs.officeEnvironment"></div>
        <!-- <img class="about_06" src="../assets/about_06.jpg" alt="" />
        <img class="about_06" src="../assets/about_07.jpg" alt="" />
        <img class="about_06" src="../assets/about_08.jpg" alt="" />
        <img class="about_06" src="../assets/about_09.jpg" alt="" /> -->
      </div>
      <div class="aboutFour_box" v-if="navIndex == 4">
        <div class="four_title">联系我们</div>
        <img class="about_06" src="../assets/about_10.jpg" alt="" />
        <div class="gs_title">{{ HomeData.siteName || "" }}私募基金管理有限公司</div>
        <div class="gs_info_box">
          客服热线：{{ HomeData.customerServicePhone }}<br />

          客服QQ: {{ HomeData.customerServiceQq }}<br />

          客服泡泡：{{ HomeData.customerServicePaoPao }}<br />

          工作时间 ：{{ HomeData.onlineTime }}<br />

          交易日:上午08:00-晚上19:00<br />

          节假日:上午12:00-晚上18:00<br />

          公司地址：{{ HomeData.officeLocation }}
        </div>
      </div>
      <div class="aboutFive_box" v-if="navIndex == 5">
        <div class="five_title">资质证书</div>
        <div v-html="aboutUs.qualificationCertificate"></div>
        <!-- <img class="img" src="../assets/about_11.jpg" alt="" />
        <img class="img" src="../assets/about_12.jpg" alt="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import brands_01 from "../assets/banner_01.png";
export default {
  name: "",
  data() {
    return {
      navIndex: 1,
      HomeData:{},
      aboutUs:{},
    };
  },
  methods: {
    changeNav(index) {
      this.navIndex = index;
    },
    getSiteHomePageSetting(){
    this.$api.getSiteHomePageSetting().then((res)=>{
      if(res.data.status == 0){
        try{
            this.HomeData = res.data.data.homePage;
            this.aboutUs = res.data.data.aboutUs;
        }catch{
          
        }
      }
    })
   },
  },
  mounted() {
    //dom 准备完毕
  },
  created() {
    //应用创建
    this.getSiteHomePageSetting();
    //应用创建
    this.$bus.$on("about", (index) => {
      this.navIndex = index + 1;
    });
   
  },
};
</script>

<style lang="less" scoped>
.about {
  width: 100%;
  background: #f8f7f7;

  .about_header_box {
    background-image: url("../assets/about_01.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 323px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .txt_box {
      text-align: center;

      .title_one {
        color: #fff;
        font-size: 40px;
        font-weight: 500;
      }

      .title_two {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .nav_container {
      width: 100%;
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .nav_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 960px;
        background: rgba(0, 0, 0, 0.6);
        height: 60px;

        .nav_list {
          display: flex;

          .nav_item {
            height: 60px;
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            color: white;
            cursor: pointer;
          }

          .nav_item_select {
            height: 60px;
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            color: #231815;
            background: #f7b329;
            cursor: pointer;
          }
        }
      }
    }
  }

  .about_content {
    padding: 40px 0;
    width: 1200px;
    margin: 0 auto;

    .aboutOne_box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .one_title {
        font-size: 36px;
        color: #231815;
      }

      .one_lable {
        font-size: 20px;
        width: 100%;
        color: #231815;
        margin-top: 32px;
        padding-bottom: 20px;
        text-align: center;
        border-bottom: 1px solid rgb(229, 229, 229);
      }

      .one_content {
        font-size: 16px;
        margin-top: 20px;
        color: #231815;
        line-height: 1.5;
      }

      .one_tags_box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 60px;

        .tag_item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .about_02 {
            width: 124px;
            height: 138px;
          }

          .title {
            font-size: 16px;
            font-weight: 500;
            color: #231815;
          }
        }
      }
    }

    .aboutTwo_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 960px;
      margin: 0px auto;

      .two_title {
        font-size: 36px;
        color: #231815;
      }

      .about_05 {
        width: 100%;
        margin-top: 40px;
      }

      .two_info_title {
        text-align: left;
        width: 100%;
        font-size: 16px;
        color: #000;
        font-weight: bold;
      }

      .two_info_content {
        width: 100%;
        color: #515a6e;
        font-size: 14px;
        margin-top: 20px;
        text-align: left;
      }
    }

    .aboutThree_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .three_titl {
        font-size: 36px;
        color: #231815;
        margin-bottom: 20px;
      }

      .about_06 {
        width: 100%;
        margin-top: 10px;
      }
    }

    .aboutFour_box {
      width: 960px;
      margin: 0 auto;

      .four_title {
        font-size: 36px;
        color: #231815;
        margin-bottom: 20px;
        text-align: center;
      }

      .about_06 {
        width: 960px;
        margin-top: 10px;
      }

      .gs_title {
        font-size: 16px;
        color: black;
        margin-top: 5px;
        margin-bottom: 30px;
      }

      .gs_info_box {
        font-size: 16px;
        color: black;
      }
    }

    .aboutFive_box {
      width: 960px;
      margin: 0 auto;

      .five_title {
        font-size: 36px;
        color: #231815;
        margin-bottom: 20px;
        text-align: center;
      }
      .img {
        margin-top: 30px;
        width: 100%;
      }
    }
  }
}
</style>